<template>
  <div class="contain">
    <div class="head">
      <img src="@/assets/404.jpg">
    </div>
    <div class="middle">{{title}}</div>
    <div class="back" @click="back">
      <div>{{backTitle}}</div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'r-404',
  data() {
    return {
      title: '很抱歉，你访问的页面不存在~',
      backTitle: '返回首页',
    }
  },
  methods: {
    back() {
      this.$router.push('/');
    }
  }
}
</script>

<style lang="scss" scoped>
  .contain {
    width: 100%;
    text-align: center;
    padding: 100px 0px;
    .head {
      img {
          width: 245px;
      }
    }
    .middle {
      margin: 40px 0px;
      font-size: 20px;
      letter-spacing: 2px;
    }
    .back {
      div {
        border: 1px solid rgb(108, 154, 213);
        letter-spacing: 2px;
        display: inline-block;
        padding: 10px 40px;
        border-radius: 30px;
      }
    }
  }
</style>

